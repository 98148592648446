.call-button {
  position: fixed;
  bottom: 20px;
  left: 20px; /* Position on the left side */
  background-color: white; /* Green color for the call button */
  color: black;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: transform 0.3s;
  text-align: center;
}

.call-button:hover {
  transform: scale(1.1); /* Slight enlarge on hover */
}

.call-icon {
  font-size: 24px; /* Adjust icon size */
}

@media (max-width: 768px) {
  /* Ensure the button stays visible on smaller screens */
  .call-button {
    bottom: 10px;
    left: 10px;
  }
}
