.feature-section {
  padding: 40px 0px;
  overflow: hidden;
}

.feature-section .slick-active {
  border: none !important;
}

.feature-section .card {
  border: none !important;
  text-align: center;
  padding: 0px 14px;
  margin-bottom: 14px;
}

.feature-section .card img {
  width: 75px;
  margin: 0px auto;
  margin-bottom: 14px;
}

.feature-section .card .card-title {
  font-size: var(--subTitle);
  padding: 12px 0px;
  font-weight: bold;
}

.feature-section .card .card-text {
  font-size: var(--bodyText);
  line-height: 22px;
  color: var(--textclr);
}
.feature-section .card:hover .card-title {
  color: var(--primaryClr);
}
