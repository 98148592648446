.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366; 
  color: white;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; 
  transition: transform 0.3s;
  text-align: center;
}

.whatsapp-button:hover {
  transform: scale(1.1); 
}

.whatsapp-icon {
  font-size: 24px;
}

@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 10px;
    right: 10px;
  }
}
