/* General card styling */
.popular {
  padding: 10px;
  border-radius: 15px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
}

.popular:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.popular img {
  max-height: 300px;
  min-height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.5s ease;
  filter: brightness(0.85);
}

.popular:hover img {
  transform: scale(1.05);
  filter: brightness(1);
}

/* Card body styling */
.popular .card-body {
  padding: 15px;
  text-align: center;
}

.popular .card-body .card-title {
  font-size: 22px; /* Increased font size */
  font-weight: 700; /* Bold font weight */
  color: #333; /* Default color */
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.popular:hover .card-body .card-title {
  color: var(--primaryClr); /* Color transition on hover */
}

.popular .card-body .included-text {
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
  text-align: left;
}

.popular .card-body .badge {
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 8px;
}

/* Footer styling */
.popular .card-footer {
  background: none;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popular .card-footer p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.popular .card-footer p strong {
  font-weight: bold;
  color: #000;
  font-size: 18px; /* Increased font size for the price */
}

.small-text {
  font-size: 12px;
  color: #999;
}

.primaryBtn {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--primaryClr);
  color: #fff;
  font-size: 16px;
  border-radius: 30px;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.primaryBtn:hover {
  background-color: #218838;
  text-decoration: none;
}

/* Star rating styling */
.popular .card-footer .small-text i {
  color: #ffc107;
}
